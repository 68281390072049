@import url("https://fonts.googleapis.com/css2?family=Paytone+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Passion+One:wght@400;700;900&display=swap');

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

html,
body,
#__next {
  font-family: "Paytone One", sans-serif;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  scrollbar-width: thin !important;
  scrollbar-color: #444 #666 !important;
}

.white {
  color: white !important;
}

.bold {
  font-weight: bold !important;
}

.boldy {
  font-weight: 500;
}

.word-wrap {
  word-wrap: anywhere;
}

.unstyle-anchors a {
  color: inherit;
  text-decoration: none;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.noUnderline {
  text-decoration: none;
}

/* set carousel styles */
.carousel.carousel-slider {
  overflow: unset !important;
}
.carousel .control-dots {
  margin: -20px 0 !important;
}

/* styles for my-roster cards */
.battlePoint {
  -webkit-text-stroke-width: 6px;
  -webkit-text-fill-color: #000000;
  -webkit-text-stroke-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image:-webkit-linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
  filter: drop-shadow(1px 1px 2px black);
  font-style: italic;
  padding-right: 8px;
  padding-left: 8px;
  height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 4%;
  padding-top: 4%;
  box-sizing: content-box;
  font-family: 'Passion One' !important;
}

.lifePoint {
  text-transform: uppercase;
  background: linear-gradient(45deg, #3FDB3A 30%, #8cf189 50%, #3FDB3A 70%);
  -webkit-background-clip: text;
  background-clip: text;
  font-style: italic;
  -webkit-text-fill-color: transparent;
  font-family: 'Passion One' !important;
  padding-right: 4px;
  padding-left: 4px;
}